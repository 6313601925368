import { ImageBlock1 } from './_imagesBlocks/ImageBlock1/ImageBlock1'
import { ImageBlock2 } from './_imagesBlocks/ImageBlock2/ImageBlock2'
import { ImageBlock3 } from './_imagesBlocks/ImageBlock3/ImageBlock3'
import { ImageBlock4 } from './_imagesBlocks/ImageBlock4/ImageBlock4'
import { AccordionContentSlide } from './VettingAccordion/VettingAccordion'

export const PAGE_VETTING_BLOCK_SLIDES: Array<AccordionContentSlide> = [
  {
    title: 'Pre-screening',
    description: [
      'At least 3 years of work experience',
      'English knowledge',
      '4-8 hrs overlap with US timezone',
    ],
    order: 0,
    images: <ImageBlock4 />,
  },
  {
    title: 'Technical quizzes',
    description: 'Fundamental programming concepts',
    order: 1,
    images: <ImageBlock1 />,
  },
  {
    title: 'Live coding challenges',
    description: 'Multiple signals captured to establish coding proficiency',
    order: 2,
    images: <ImageBlock2 />,
  },
  {
    title: 'Recruiter Interview',
    description:
      'Soft skills: motivation, communication, teamwork, startup values',
    order: 3,
    images: <ImageBlock3 />,
  },
]
