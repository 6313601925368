import React from 'react'

import { FAQItem } from 'src/data/FAQData/FAQData'

import { FAQQuestionAccordion } from './FAQQuestionAccordion/FAQQuestionAccordion'

import s from './FAQBlockQuetions.module.scss'

export type FAQBlockQuestionsProps = {
  faq: Array<FAQItem>
}
export const FAQBlockQuestions: React.FC<FAQBlockQuestionsProps> = ({
  faq,
}) => {
  return (
    <ul className={s.FAQBlockQuestions__list}>
      {faq.map((faqItem, i) => (
        <li key={i} className={s.FAQBlockQuestions__question}>
          <FAQQuestionAccordion item={faqItem} />
        </li>
      ))}
    </ul>
  )
}
