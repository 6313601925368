import React from 'react'

import clsx from 'clsx'

import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'

import { AccordionItemCircle } from './AccordionItemCircle/AccordionItemCircle'
import { AccordionItemDescription } from './AccordionItemDescription/AccordionItemDescription'

import s from './AccordionItem.module.scss'

export type AccordionItemProps = {
  title: string
  description: string | string[]
  order: number
  isActive: boolean

  onChangeActiveItem: (changedOrder: number) => void
  onClick: () => void
}

export const AccordionItem: React.FC<AccordionItemProps> = ({
  title,
  description,
  order,
  isActive,
  onChangeActiveItem,
  onClick,
}) => {
  const handleAnimationEnd = () => {
    onChangeActiveItem(order)
  }

  return (
    <div
      className={clsx(s.AccordionItem, {
        [s.AccordionItem_notActive]: !isActive,
      })}
      onClick={onClick}
    >
      <AccordionItemCircle
        isActive={isActive}
        order={order}
        onAnimationEnd={handleAnimationEnd}
      />

      <div>
        <Typography
          className={s.AccordionItem__title}
          variant={EnumTypographyVariants.H5}
          text={title}
        />

        <AccordionItemDescription
          description={description}
          isActive={isActive}
        />
      </div>
    </div>
  )
}
