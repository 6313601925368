import React, { useEffect, useState } from 'react'

import { useEventListener } from '@insquad/tools'

export type MobilePaginationBlockProps<E> = {
  paginationBreakpointMaxWidth: number
  elementsPerShow: number
  elements: Array<E>
  renderShowMoreTrigger: (onShowMore: () => void) => React.ReactNode
  renderElement: (element: E, index: number) => React.ReactNode
}

export const MobilePaginationBlock = <E,>({
  paginationBreakpointMaxWidth,
  elementsPerShow,
  elements,
  renderShowMoreTrigger,
  renderElement,
}: MobilePaginationBlockProps<E>) => {
  const [shownElements, setShownElements] = useState<Array<E>>([])

  const handleResize = () => {
    if (window.innerWidth < paginationBreakpointMaxWidth) {
      setShownElements(elements.slice(0, elementsPerShow))
    } else {
      setShownElements(elements)
    }
  }

  const handleShowMore = () => {
    setShownElements((prev) => elements.slice(0, prev.length + elementsPerShow))
  }

  useEventListener('resize', handleResize)

  useEffect(() => {
    handleResize()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {shownElements.map((element, i) => renderElement(element, i))}

      {shownElements.length < elements.length &&
        renderShowMoreTrigger(handleShowMore)}
    </>
  )
}
