import React from 'react'

import clsx from 'clsx'

import { GetMatchedForm } from './GetMatchedForm/GetMatchedForm'
import { GetMatchedImage } from './GetMatchedImage/GetMatchedImage'

import s from './PageGetMatchedBlock.module.scss'

export type PageGetMatchedBlockProps = {
  className?: string
  pageAnalytic: string
}

export const PageGetMatchedBlock: React.FC<PageGetMatchedBlockProps> = ({
  className,
  pageAnalytic,
}) => {
  return (
    <div className={clsx(s.PageGetMatchedBlock, className)}>
      <div className={clsx(s.PageGetMatchedBlock__inner, 'container')}>
        <div className={s.PageGetMatchedBlock__block}>
          <GetMatchedForm pageAnalytic={pageAnalytic} />
        </div>

        <div className={s.PageGetMatchedBlock__block}>
          <GetMatchedImage />
        </div>
      </div>
    </div>
  )
}
