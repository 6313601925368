import { EntrepreneurIcon } from './_icons/EntrepreneurIcon'
import { ForbesLogoIcon } from './_icons/ForbesLogoIcon'
import { MediumLogoIcon } from './_icons/MediumLogoIcon'
import { MediaBlockSlideProps } from './MediaBlockSlider/MediaBlockSlide/MediaBlockSlide'

export const PAGE_MEDIA_BLOCK_SLIDES: Array<MediaBlockSlideProps> = [
  {
    description:
      'Can Remote Collaboration Help To Reverse The Decline In Research Productivity?',
    logo: <ForbesLogoIcon />,
    link: 'https://www.forbes.com/sites/adigaskell/2022/06/14/can-remote-collaboration-help-to-reverse-the-decline-in-research-productivity/?sh=479e70456d55',
  },
  {
    description:
      'How Particle. One Hired a Quant Developer for Their Crypto Branch in Three Weeks',
    logo: <MediumLogoIcon />,
    link: 'https://insquad.medium.com/how-particle-one-hired-a-quant-developer-for-their-crypto-branch-in-three-weeks-c3760ba506ff',
  },
  {
    description:
      'Recruiters Are Struggling To Meet Unprecedented Demand. Can Tapping Global Talent Be the Answer?',
    logo: <EntrepreneurIcon />,
    link: 'https://www.entrepreneur.com/en-in/news-and-trends/recruiters-are-struggling-to-meet-unprecedented-demand-can/429236',
  },
]
