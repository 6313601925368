import React from 'react'

import Image from 'next/image'

import { UnknownObject } from 'src/utils/reactTypes'

import getMatchedImg from './getMatchedImg.png'

import s from './GetMatchedImage.module.scss'

export const GetMatchedImage: React.FC<UnknownObject> = () => {
  return (
    <div className={s.GetMatchedImage}>
      <Image src={getMatchedImg.src} layout="fill" unoptimized alt="" />
    </div>
  )
}
