import React from 'react'

import { Button } from '@insquad/tools'

import { Input } from 'src/common/components/_form/Input/Input'
import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'
import { useAuthRedirect } from 'src/common/hooks/useAuthRedirect'
import { AnalyticEventEnum } from 'src/utils/analytic/analyticEventEnum'
import { useAnalytic } from 'src/utils/analytic/useAnalytic'

import s from './GetMatchedForm.module.scss'

export type GetMatchedFormProps = {
  pageAnalytic: string
}

export const GetMatchedForm: React.FC<GetMatchedFormProps> = ({
  pageAnalytic,
}) => {
  const { analytic } = useAnalytic()
  const { handleSubmitHireNow } = useAuthRedirect()

  const handleSignUp = (e: React.FormEvent<HTMLFormElement>) => {
    analytic.event(AnalyticEventEnum.SIGN_UP, {
      page: pageAnalytic,
      from: 'input-bottom',
    })

    handleSubmitHireNow(e)
  }

  return (
    <form onSubmit={handleSignUp}>
      <Typography
        variant={EnumTypographyVariants.H3}
        text="Get matched with superb candidates in 1 day. Start risk-free trial now!"
        className={s.GetMatchedForm__title}
      />

      <div className={s.GetMatchedForm__formInner}>
        <Input
          containerProps={{ className: s.GetMatchedForm__input }}
          placeholder="Enter your e-mail"
        />
        <Button
          id="button-matched-block-hirenow"
          type="submit"
          className={s.GetMatchedForm__btn}
        >
          Hire now
        </Button>
      </div>
    </form>
  )
}
