import React from 'react'

import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'
import { UnknownObject } from 'src/utils/reactTypes'

import { FounderCard } from './FounderCard/FounderCard'
import { PAGE_FOUNDERS_BLOCK_CARDS } from './pageFoundersBlockCards'

import s from './PageFoundersBlock.module.scss'

export const PageFoundersBlock: React.FC<UnknownObject> = () => {
  return (
    <div className={s.PageFoundersBlock}>
      <div className="container">
        <Typography
          variant={EnumTypographyVariants.H2}
          className={s.PageFoundersBlock__title}
          text="Insquad founders"
        />

        <div className={s.PageFoundersBlock__cards}>
          {PAGE_FOUNDERS_BLOCK_CARDS.map((card, i) => (
            <FounderCard key={i} {...card} />
          ))}
        </div>
      </div>
    </div>
  )
}
