import React from 'react'

export type StarIconProps = React.SVGProps<SVGSVGElement>

export const StarIcon: React.FC<StarIconProps> = ({ ...svgProps }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...svgProps}>
      <path
        d="M11.0002 1.83337L13.8327 7.57171L20.1668 8.49754L15.5835 12.9617L16.6652 19.2684L11.0002 16.2892L5.33516 19.2684L6.41683 12.9617L1.8335 8.49754L8.16766 7.57171L11.0002 1.83337Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
