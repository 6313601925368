import React, { useEffect } from 'react'
import { useSpringRef, useTransition, animated, config } from 'react-spring'

import clsx from 'clsx'

import { Icon } from 'src/common/components/Icon/Icon'
import { ArrowUpIcon } from 'src/common/components/icons/ArrowUpIcon'
import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'

import { AccordionContentSlide } from '../../VettingAccordion'

import s from './AccordionImages.module.scss'

export type AccordionImagesProps = {
  currentSlide: AccordionContentSlide
  className?: string
}

export const AccordionImages: React.FC<AccordionImagesProps> = ({
  currentSlide,
  className,
}) => {
  const imageTransitionsRef = useSpringRef()
  const imageTransitions = useTransition(true, {
    ref: imageTransitionsRef,
    keys: currentSlide.order,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.slow,
  })

  useEffect(() => {
    imageTransitionsRef.start()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlide])

  return (
    <div className={clsx(s.AccordionImages, className)}>
      {/* <Typography
        className={s.AccordionImages__btn}
        variant={EnumTypographyVariants.H5}
        as="a"
        href="/"
      >
        <span>Learn more</span>
        <Icon
          className={s.AccordionImages__btnIcon}
          boxSize={32}
          icon={<ArrowUpIcon />}
        />
      </Typography> */}

      {imageTransitions(
        (style, isVisible) =>
          isVisible && (
            <animated.div
              style={style}
              className={s.AccordionImages__imagesWrapper}
            >
              {currentSlide.images}
            </animated.div>
          )
      )}
    </div>
  )
}
