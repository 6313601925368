import React from 'react'

export type ArrowUpIconProps = React.HtmlHTMLAttributes<SVGElement>

export const ArrowUpIcon: React.FC<ArrowUpIconProps> = ({ ...svgProps }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M6.4165 15.5833L15.5832 6.41663"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.4165 6.41663H15.5832V15.5833"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
