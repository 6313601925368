import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

import { AwardBlockInfo } from './AwardBlockInfo/AwardBlockInfo'
import { AwardBlockTitle } from './AwardBlockTitle/AwardBlockTitle'
import { PAGE_AWARD_BLOCK_CARDS } from './pageAwardBlockCards'

export const PageAwardBlock: React.FC<UnknownObject> = () => {
  return (
    <div className="container">
      <AwardBlockTitle />
      <AwardBlockInfo cards={PAGE_AWARD_BLOCK_CARDS} />
    </div>
  )
}
