import React from 'react'

import clsx from 'clsx'

import { AnchorIdsEnum } from 'src/common/enums/anchorIdsEnum'
import { DATA_FAQ } from 'src/data/FAQData/FAQData'
import { UnknownObject } from 'src/utils/reactTypes'

import { FAQBlockImage } from './FAQBlockImage/FAQBlockImage'
import { FAQBlockQuestions } from './FAQBlockQuestions/FAQBlockQuetions'

import s from './PageFAQBlock.module.scss'

export const PageFAQBlock: React.FC<UnknownObject> = () => {
  return (
    <div
      id={AnchorIdsEnum.FAQ_BLOCK_ANCHOR}
      className={clsx(s.PageFAQBlock__inner, 'container')}
    >
      <div className={s.PageFAQBlock__block}>
        <FAQBlockImage />
      </div>

      <div className={s.PageFAQBlock__block}>
        <FAQBlockQuestions faq={DATA_FAQ} />
      </div>
    </div>
  )
}
