import { EmergeBottomLogo } from './_logos/emerge/EmergeBottomLogo'
import { EmergeTopLogo } from './_logos/emerge/EmergeTopLogo'
import { GlassdoorBottomLogo } from './_logos/glassdoor/GlassdoorBottomLogo'
import { GlassdoorTopLogo } from './_logos/glassdoor/GlassdoorTopLogo'
import { ProductHuntBottomLogo } from './_logos/productHunt/ProductHuntBottomLogo'
import { ProductHuntTopLogo } from './_logos/productHunt/ProductHuntTopLogo'
import { TrustPilotBottomLogo } from './_logos/trustpilot/TrustPilotBottomLogo'
import { TrustPilotTopLogo } from './_logos/trustpilot/TrustPilotTopLogo'
import { AwardBlockCardProps } from './AwardBlockInfo/AwardBlockCard/AwardBlockCard'

export const PAGE_AWARD_BLOCK_CARDS: Array<AwardBlockCardProps> = [
  {
    href: 'https://www.glassdoor.co.uk/Reviews/Insquad-Reviews-E6959392.htm',
    topLogo: <GlassdoorTopLogo />,
    bottomLogo: <GlassdoorBottomLogo />,
  },
  {
    href: 'https://www.trustpilot.com/review/insquad.com',
    topLogo: <TrustPilotTopLogo />,
    bottomLogo: <TrustPilotBottomLogo />,
  },
  {
    href: 'https://emergeconf.io',
    topLogo: <EmergeTopLogo />,
    bottomLogo: <EmergeBottomLogo />,
  },
  {
    href: 'https://www.producthunt.com/products/insquad',
    topLogo: <ProductHuntTopLogo />,
    bottomLogo: <ProductHuntBottomLogo />,
  },
]
