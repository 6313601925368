export type FAQItem = {
  question: string
  answer: string | Array<string>
}

export const DATA_FAQ: Array<FAQItem> = [
  {
    question: 'Why do the best remote developers join Insquad?',
    answer: [
      "We don't just vet developers. Our goal is to help talents fully realize their potential working with ambitious tasks. After passing our hard vetting once, the developer can no longer worry about his career; Insquad offers all the opportunities for constant professional growth as well as self-education.",
      "Material motivation of the Insquad developers is also our top priority. The Insquad developer's income will always be higher than he could get in his home country. So, working with Insquad is interesting, profitable and promising!",
    ],
  },
  {
    question: 'What does the Insquad vetting process look like?',
    answer:
      'Insquad has developed a proprietary system to vet developer soft and hard skills over these areas: tech proficiency, communication skills, motivation, leadership. This ensures that every Insquad developer you get matched with will deliver Insquad promise—the best remote developers available on the market.',
  },
  {
    question: 'What is an Insquad risk-free trial?',
    answer:
      'The first two weeks that Insquad developer works on your project are a trial. If you are happy with him or her—then you keep working and pay his or her salary including these two weeks. If the developer can not deliver the results during this period—you pay nothing. Insquad will be glad to offer you other developers in the same stack and tech level.',
  },
  {
    question: 'Where are Insquad developers located?',
    answer:
      "We have developers from all over the world: Asia, Africa, South and Central America. When matching companies with the talents, Insquad ensures that each developer is able to work at least four hours in the company's time zone.",
  },
  {
    question: 'What about paperwork?',
    answer:
      'Your company simply enters into an agreement with Insquad Inc. After that, hiring, legal, tax and payments are all covered by Insquad. During work, the company communicates directly with the developer.',
  },
  {
    question: 'What is the minimum contract period with the developer?',
    answer:
      'Usually, Insquad developers work on one project for at least three months. If you are satisfied with this collaboration, you can pay the fee and start working with the developer directly after this period.',
  },
  {
    question: 'How long does it take to hire developers?',
    answer:
      "It takes us one to three days to show you complete pre-vetted developers' profiles and schedule interviews. The whole hiring process could be completed in just two weeks, including the notice period. In some cases, hiring a developer can take longer: For example, if a developer has an extended notice period when resigning.",
  },
  {
    question:
      'In which kind of tech stacks does Insquad hire remote developers?',
    answer:
      'Our pre-vetted pool of developers includes more than 100 technologies: React, Node.JS, Python, Swift, React, Android, Java, Ruby, Golang, C#, C++, .Net—and many others!',
  },
]
