import React from 'react'

import Image from 'next/image'

import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'
import { UnknownObject } from 'src/utils/reactTypes'

import AlchemistIcon from './_icons/alchemistIcon.svg'
import ForbesIcon from './_icons/forbesIcon.svg'

import s from './Footer1.module.scss'

export const Footer1: React.FC<UnknownObject> = () => {
  return (
    <div className={s.Footer1}>
      <Image
        layout="fixed"
        width={77}
        height={88}
        src={AlchemistIcon.src}
        alt=""
      />
      <Image
        layout="fixed"
        width={118}
        height={88}
        src={ForbesIcon.src}
        alt=""
      />

      <div>
        <Typography
          variant={EnumTypographyVariants.H9}
          text="Alchemist Recruitment Mentor"
          className={s.Footer1__text}
        />

        <Typography
          variant={EnumTypographyVariants.H9}
          text="Forbes Business Council"
          className={s.Footer1__text}
        />
      </div>
    </div>
  )
}
