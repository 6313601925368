import React from 'react'

import Image from 'next/image'

import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'
import { UnknownObject } from 'src/utils/reactTypes'

import FAQImage from './FAQImage.png'

import s from './FAQBlockImage.module.scss'

export const FAQBlockImage: React.FC<UnknownObject> = () => {
  return (
    <div className={s.FAQBlockImage}>
      <Typography
        variant={EnumTypographyVariants.H3}
        text="Frequently asked questions"
        className={s.FAQBlockImage__title}
      />

      <div className={s.FAQBlockImage__image}>
        <Image src={FAQImage.src} layout="fill" unoptimized alt="" />
      </div>
    </div>
  )
}
