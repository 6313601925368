import React from 'react'

import clsx from 'clsx'

import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'
import { UnknownObject } from 'src/utils/reactTypes'

import { AwardLeftIcon } from './AwardLeftIcon'
import { AwardRightIcon } from './AwardRightIcon'

import s from './AwardBlockTitle.module.scss'

export const AwardBlockTitle: React.FC<UnknownObject> = () => {
  return (
    <div className={s.AwardBlockTitle}>
      <div
        className={clsx(s.AwardBlockTitle__icon, s.AwardBlockTitle__icon_left)}
      >
        <AwardLeftIcon />
      </div>

      <div
        className={clsx(s.AwardBlockTitle__icon, s.AwardBlockTitle__icon_right)}
      >
        <AwardRightIcon />
      </div>

      <Typography
        className={s.AwardBlockTitle__title}
        variant={EnumTypographyVariants.H2}
        text="Award winner"
      />
    </div>
  )
}
