import React from 'react'

export type ResumeIconProps = React.HtmlHTMLAttributes<SVGElement>

export const ResumeIcon: React.FC<ResumeIconProps> = ({ ...svgProps }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M12.8332 1.83337H5.49984C5.01361 1.83337 4.54729 2.02653 4.20347 2.37034C3.85966 2.71416 3.6665 3.18048 3.6665 3.66671V18.3334C3.6665 18.8196 3.85966 19.2859 4.20347 19.6297C4.54729 19.9736 5.01361 20.1667 5.49984 20.1667H16.4998C16.9861 20.1667 17.4524 19.9736 17.7962 19.6297C18.14 19.2859 18.3332 18.8196 18.3332 18.3334V7.33337L12.8332 1.83337Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8335 1.83337V7.33337H18.3335"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6668 11.9166H7.3335"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6668 15.5834H7.3335"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.16683 8.25H8.25016H7.3335"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
