import React from 'react'

import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'
import { UnknownObject } from 'src/utils/reactTypes'

import s from './Footer3.module.scss'

export const Footer3: React.FC<UnknownObject> = () => {
  return (
    <Typography variant={EnumTypographyVariants.H8} className={s.Footer3}>
      Knows 1001 memes on any subject and eats a Snickers a day.
    </Typography>
  )
}
