import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

export const GlassdoorTopLogo: React.FC<UnknownObject> = () => {
  return (
    <svg
      width="112"
      height="36"
      viewBox="0 0 112 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.396 27.364C49.434 27.364 48.511 27.2383 47.627 26.987C46.743 26.7357 45.976 26.402 45.326 25.986L45.924 23.698C46.5653 24.0447 47.2327 24.3437 47.926 24.595C48.6193 24.8463 49.3993 24.972 50.266 24.972C51.4273 24.972 52.3677 24.647 53.087 23.997C53.8063 23.3383 54.166 22.4587 54.166 21.358C54.166 20.11 53.776 19.161 52.996 18.511C52.216 17.861 51.202 17.536 49.954 17.536C49.2953 17.536 48.6713 17.5837 48.082 17.679C47.5013 17.7743 46.9033 17.9 46.288 18.056L47.406 8.8H55.986L55.882 11.14H49.434L48.758 16.834L48.212 15.768C48.602 15.69 49.0137 15.6293 49.447 15.586C49.8803 15.534 50.318 15.508 50.76 15.508C52.0513 15.508 53.152 15.7637 54.062 16.275C54.972 16.7777 55.6653 17.471 56.142 18.355C56.6273 19.239 56.87 20.24 56.87 21.358C56.87 22.5887 56.584 23.6547 56.012 24.556C55.4487 25.4487 54.6773 26.142 53.698 26.636C52.7273 27.1213 51.6267 27.364 50.396 27.364ZM57.8064 29.21L64.7744 8.8H67.2444L60.2764 29.21H57.8064ZM72.8667 27.364C71.9047 27.364 70.9817 27.2383 70.0977 26.987C69.2137 26.7357 68.4467 26.402 67.7967 25.986L68.3947 23.698C69.036 24.0447 69.7034 24.3437 70.3967 24.595C71.09 24.8463 71.87 24.972 72.7367 24.972C73.898 24.972 74.8384 24.647 75.5577 23.997C76.277 23.3383 76.6367 22.4587 76.6367 21.358C76.6367 20.11 76.2467 19.161 75.4667 18.511C74.6867 17.861 73.6727 17.536 72.4247 17.536C71.766 17.536 71.142 17.5837 70.5527 17.679C69.972 17.7743 69.374 17.9 68.7587 18.056L69.8767 8.8H78.4567L78.3527 11.14H71.9047L71.2287 16.834L70.6827 15.768C71.0727 15.69 71.4844 15.6293 71.9177 15.586C72.351 15.534 72.7887 15.508 73.2307 15.508C74.522 15.508 75.6227 15.7637 76.5327 16.275C77.4427 16.7777 78.136 17.471 78.6127 18.355C79.098 19.239 79.3407 20.24 79.3407 21.358C79.3407 22.5887 79.0547 23.6547 78.4827 24.556C77.9194 25.4487 77.148 26.142 76.1687 26.636C75.198 27.1213 74.0974 27.364 72.8667 27.364Z"
        fill="currentColor"
      />
      <path
        d="M18 3L22.635 12.39L33 13.905L25.5 21.21L27.27 31.53L18 26.655L8.73 31.53L10.5 21.21L3 13.905L13.365 12.39L18 3Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
