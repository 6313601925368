import React from 'react'

import { useToggle } from '@insquad/tools'

import { Icon } from 'src/common/components/Icon/Icon'
import { MinusIcon } from 'src/common/components/icons/MinusIcon'
import { PlusIcon } from 'src/common/components/icons/PlusIcon'
import { Switchable } from 'src/common/components/Switchable/Switchable'
import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'
import { FAQItem } from 'src/data/FAQData/FAQData'

import s from './FAQQuestionAccordion.module.scss'

export type FAQQuestionAccordionProps = {
  item: FAQItem
}

export const FAQQuestionAccordion: React.FC<FAQQuestionAccordionProps> = ({
  item,
}) => {
  const { answer, question } = item
  const toggler = useToggle()

  const renderAnswer = (text: string) => (
    <Typography
      variant={EnumTypographyVariants.H8}
      className={s.FAQQuestionAccordion__description}
      as="p"
    >
      {text}
    </Typography>
  )

  return (
    <div onClick={toggler.toggle} className={s.FAQQuestionAccordion}>
      <div className={s.FAQQuestionAccordion__titleWrapper}>
        <Typography
          variant={EnumTypographyVariants.H5}
          className={s.FAQQuestionAccordion__title}
        >
          {question}
        </Typography>

        <Icon
          icon={toggler.value ? <MinusIcon /> : <PlusIcon />}
          className={s.FAQQuestionAccordion__icon}
        />
      </div>

      <Switchable isOpen={toggler.value}>
        {Array.isArray(answer) &&
          answer.map((text, i) => (
            <React.Fragment key={i}>{renderAnswer(text)}</React.Fragment>
          ))}

        {!Array.isArray(answer) && renderAnswer(answer)}
      </Switchable>
    </div>
  )
}
