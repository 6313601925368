import React from 'react'

import SwiperCore from 'swiper'

import { Swiper, SwiperSlide } from 'swiper/react'

import {
  MediaBlockSlide,
  MediaBlockSlideProps,
} from './MediaBlockSlide/MediaBlockSlide'

export type MediaBlockSliderProps = {
  slides: Array<MediaBlockSlideProps>
  onSwiper: (ref: SwiperCore) => void
}

export const MediaBlockSlider: React.FC<MediaBlockSliderProps> = ({
  slides,
  onSwiper,
}) => (
  <div className={'container'}>
    <Swiper
      slidesPerView="auto"
      spaceBetween={22}
      onSwiper={onSwiper}
      mousewheel={{
        forceToAxis: true,
        sensitivity: 5,
      }}
      grabCursor
      loop
    >
      {slides.map((slide, i) => (
        <SwiperSlide key={i} style={{ maxWidth: 392 }}>
          <MediaBlockSlide {...slide} />
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
)
