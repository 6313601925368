import React from 'react'

import clsx from 'clsx'

import Image from 'next/image'

import { Icon } from 'src/common/components/Icon/Icon'
import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'

import startupBlockImg from './startupBlockImg.png'

import s from './StartupBlockInfo.module.scss'

export type StartupBlockInfoItem = {
  title: string
  description: string
  icon: React.ReactNode
}

export type StartupBlockInfoProps = {
  title: string
  items: Array<StartupBlockInfoItem>
}

export const StartupBlockInfo: React.FC<StartupBlockInfoProps> = ({
  title,
  items,
}) => {
  return (
    <div className={s.StartupBlockInfo}>
      <div>
        <Typography
          className={s.StartupBlockInfo__title}
          variant={EnumTypographyVariants.H2}
          text={title}
        />

        <div className={s.StartupBlockInfo__content}>
          <ul className={s.StartupBlockInfo__list}>
            {items.map(({ description, icon, title }, i) => (
              <li key={i} className={s.StartupBlockInfo__listItemWrapper}>
                <div className={s.StartupBlockInfo__listItem}>
                  <Icon
                    icon={icon}
                    className={s.StartupBlockInfo__listItemIcon}
                  />

                  <div>
                    <Typography
                      className={s.StartupBlockInfo__listItemTitle}
                      variant={EnumTypographyVariants.H4}
                      text={title}
                    />

                    <Typography
                      className={s.StartupBlockInfo__listItemDescription}
                      variant={EnumTypographyVariants.H7}
                      text={description}
                    />
                  </div>
                </div>
              </li>
            ))}
          </ul>

          <div
            className={clsx(
              s.StartupBlockInfo__image,
              s.StartupBlockInfo__mobile
            )}
          >
            <Image src={startupBlockImg.src} layout="fill" unoptimized alt="" />
          </div>
        </div>
      </div>

      <div
        className={clsx(s.StartupBlockInfo__image, s.StartupBlockInfo__desktop)}
      >
        <Image src={startupBlockImg.src} layout="fill" unoptimized alt="" />
      </div>
    </div>
  )
}
