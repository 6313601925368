import React from 'react'

import clsx from 'clsx'

import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'

import { AccordionImages } from '../_common/AccordionImages/AccordionImages'
import { AccordionList } from '../_common/AccordionList/AccordionList'
import { AccordionContentSlide } from '../VettingAccordion'

import s from './AccordionDesktop.module.scss'

export type AccordionDesktopProps = {
  slides: Array<AccordionContentSlide>
  activeSlideOrder: number
  isVisible: boolean
  className: string

  setActiveSlideOrder: React.Dispatch<React.SetStateAction<number>>
}

export const AccordionDesktop: React.FC<AccordionDesktopProps> = ({
  slides,
  isVisible,
  className,
  activeSlideOrder,
  setActiveSlideOrder,
}) => {
  return (
    <div className={clsx(s.AccordionDesktop, className)}>
      <div className={s.AccordionDesktop__block}>
        <Typography
          variant={EnumTypographyVariants.H3}
          text="Our vetting process lets in only top 2% developers"
          className={s.AccordionDesktop__title}
        />

        <AccordionList
          slides={slides}
          isVisible={isVisible}
          activeSlideOrder={activeSlideOrder}
          setActiveSlideOrder={setActiveSlideOrder}
        />
      </div>

      <AccordionImages
        currentSlide={slides[activeSlideOrder]}
        className={s.AccordionDesktop__block}
      />
    </div>
  )
}
