import React, { useRef, useState } from 'react'

import { useIntersectionObserver } from '@insquad/tools'

import { AccordionDesktop } from './AccordionDesktop/AccordionDesktop'
import { AccordionMobile } from './AccordionMobile/AccordionMobile'

import s from './VettingAccordion.module.scss'

export type AccordionContentSlide = {
  title: string
  description: string | Array<string>
  order: number
  images: React.ReactNode
}

export type VettingAccordionProps = {
  slides: Array<AccordionContentSlide>
}

export const VettingAccordion: React.FC<VettingAccordionProps> = ({
  slides,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const observerEntry = useIntersectionObserver(containerRef, {
    threshold: 0.35,
    freezeOnceVisible: true,
  })

  const [activeSlideOrder, setActiveSlideOrder] = useState(0)

  return (
    <div ref={containerRef}>
      <AccordionDesktop
        activeSlideOrder={activeSlideOrder}
        isVisible={!!observerEntry?.isIntersecting}
        setActiveSlideOrder={setActiveSlideOrder}
        slides={slides}
        className={s.VettingAccordion__desktop}
      />

      <AccordionMobile
        activeSlideOrder={activeSlideOrder}
        isVisible={!!observerEntry?.isIntersecting}
        setActiveSlideOrder={setActiveSlideOrder}
        slides={slides}
        className={s.VettingAccordion__mobile}
      />
    </div>
  )
}
