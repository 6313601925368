import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

export const LightningIcon: React.FC<UnknownObject> = () => {
  return (
    <svg
      width="30"
      height="50"
      viewBox="0 0 30 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4444 20.5L20.4444 1L1 25.5L17.3333 24.5L9.55556 49L29 19L13.4444 20.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}
