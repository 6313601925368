import React from 'react'

import clsx from 'clsx'

import { ContentSection } from 'src/common/components/ContentSection/ContentSection'

import PageVettingBlockBg from './pageVettingBlockBg.svg'
import { PAGE_VETTING_BLOCK_SLIDES } from './pageVettingBlockSlides'
import { VettingAccordion } from './VettingAccordion/VettingAccordion'

import s from './PageVettingBlock.module.scss'

export type PageVettingBlockProps = {
  className?: string
  gradientClassName?: string
  children?: React.ReactNode
}

export const PageVettingBlock: React.FC<PageVettingBlockProps> = ({
  className,
  gradientClassName,
  children,
}) => {
  return (
    <div
      className={clsx(s.PageVettingBlock__backgroundImage, className)}
      style={{ backgroundImage: `url(${PageVettingBlockBg.src})` }}
    >
      <div
        className={clsx(
          s.PageVettingBlock__backgroundGradient,
          gradientClassName
        )}
      >
        <ContentSection
          className={clsx(s.PageVettingBlock__vettingSection, 'container')}
          isPaddingIndent
        >
          <VettingAccordion slides={PAGE_VETTING_BLOCK_SLIDES} />
        </ContentSection>

        {children}
      </div>
    </div>
  )
}
