import React from 'react'

import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'
import { UnknownObject } from 'src/utils/reactTypes'

import s from './Footer2.module.scss'

export const Footer2: React.FC<UnknownObject> = () => {
  return (
    <Typography className={s.Footer2} variant={EnumTypographyVariants.H8}>
      Knows well HTML – how to meet ladies.
    </Typography>
  )
}
