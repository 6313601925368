import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

export const TwitterSocialIcon: React.FC<UnknownObject> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.54776 21.5122C16.6048 21.5122 21.5578 14.0061 21.5578 7.49698C21.5578 7.28381 21.5534 7.07161 21.5438 6.86029C22.5075 6.16269 23.3393 5.29895 24 4.30956C23.1177 4.70195 22.1683 4.96605 21.1723 5.08512C22.1889 4.47532 22.9694 3.51059 23.3376 2.36063C22.371 2.93424 21.3136 3.3386 20.2109 3.55629C19.3123 2.59883 18.0331 2 16.6166 2C13.8974 2 11.6923 4.20595 11.6923 6.92512C11.6923 7.31171 11.7356 7.68761 11.8201 8.04829C7.72766 7.84229 4.09878 5.8822 1.67015 2.90176C1.23273 3.65348 1.00265 4.50783 1.00341 5.37756C1.00341 7.08654 1.87268 8.59517 3.19463 9.47781C2.41268 9.45391 1.6479 9.24263 0.964585 8.86171C0.963854 8.88239 0.963854 8.90254 0.963854 8.92463C0.963854 11.3101 2.66112 13.3019 4.91419 13.7532C4.49104 13.8685 4.05439 13.9268 3.6158 13.9265C3.29907 13.9265 2.99039 13.8954 2.69039 13.8378C3.31727 15.7949 5.13502 17.2192 7.29019 17.259C5.60488 18.5804 3.4819 19.3675 1.17454 19.3675C0.782024 19.3678 0.389833 19.345 0 19.2992C2.17922 20.6965 4.76683 21.5119 7.548 21.5119"
        fill="#1DA1F2"
      />
    </svg>
  )
}
