import React, { useEffect } from 'react'
import { useSpring, animated, config, to } from 'react-spring'

import clsx from 'clsx'

import s from './AccordionItemCircle.module.scss'

export type AccordionItemCircleProps = {
  order: number
  isActive: boolean

  onAnimationEnd: () => void
}

let isAnimationEndHandled = false

export const AccordionItemCircle: React.FC<AccordionItemCircleProps> = ({
  order,
  isActive,
  onAnimationEnd,
}) => {
  const [style, styleApi] = useSpring(() => ({
    from: {
      transform: 0,
      rotate: 0,
    },
    config: {
      ...config.gentle,
      duration: 6000,
    },
    onRest() {
      const isTriggeredByAnimation = style.rotate.get() === 360

      if (isTriggeredByAnimation && !isAnimationEndHandled) {
        onAnimationEnd()
        isAnimationEndHandled = true
        setTimeout(() => {
          isAnimationEndHandled = false
        })
      }

      styleApi.set({
        transform: 0,
        rotate: 0,
      })
    },
  }))

  useEffect(() => {
    if (isActive) {
      styleApi.start({
        to: {
          transform: -60,
          rotate: 360,
        },
      })
    } else {
      styleApi.set({
        transform: 0,
        rotate: 0,
      })
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive])

  return (
    <div
      className={clsx(s.AccordionItemCircle, {
        [s.AccordionItemCircle_animated]: isActive,
      })}
      onAnimationEnd={onAnimationEnd}
    >
      <div className={s.AccordionItemCircle__order}>{order + 1}</div>

      <animated.div
        style={{
          transform: to(
            [style.transform, style.rotate],
            (transform, rotate) =>
              `translateY(${Math.round(transform)}%) rotate(${Math.round(
                rotate
              )}deg)`
          ),
        }}
        className={s.AccordionItemCircle__animation}
      />
    </div>
  )
}
