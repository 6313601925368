import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

import BottomImage from './_images/bottomImage.png'
import TopImage from './_images/topImage.png'

import s from './ImageBlock1.module.scss'

export const ImageBlock1: React.FC<UnknownObject> = () => {
  return (
    <div className={s.ImageBlock1}>
      <div className={s.ImageBlock1__topImageWrapper}>
        <div className={s.ImageBlock1__topImage}>
          <img src={TopImage.src} alt="" />
        </div>
      </div>

      <div className={s.ImageBlock1__bottomImageWrapper}>
        <div className={s.ImageBlock1__bottomImage}>
          <img src={BottomImage.src} alt="" />
        </div>
      </div>
    </div>
  )
}
