import React from 'react'

import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'

import s from './MediaBlockSlide.module.scss'

export type MediaBlockSlideProps = {
  logo: React.ReactNode
  description: string
  link: string
}

export const MediaBlockSlide: React.FC<MediaBlockSlideProps> = ({
  logo,
  description,
  link,
}) => {
  return (
    <a
      href={link}
      className={s.MediaBlockSlide__wrapper}
      target="_blank"
      rel="noreferrer"
    >
      <div className={s.MediaBlockSlide}>
        <div className={s.MediaBlockSlide__logo}>{logo}</div>

        <Typography variant={EnumTypographyVariants.H5} text={description} />
      </div>
    </a>
  )
}
