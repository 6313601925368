import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

export const AwardRightIcon: React.FC<UnknownObject> = () => {
  return (
    <svg
      width="425"
      height="162"
      viewBox="0 0 425 162"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 117H419" stroke="#9FA1FF" />
      <circle cx="422" cy="117" r="2.5" stroke="#9FA1FF" />
      <circle cx="368" cy="159" r="2.5" stroke="#9FA1FF" />
      <circle cx="330" cy="3" r="2.5" stroke="#9FA1FF" />
      <path d="M0 103.5H229.5L328.5 4.5" stroke="#9FA1FF" />
      <path d="M0 131H296L365.5 158" stroke="#9FA1FF" />
      <rect width="200" height="162" fill="url(#paint0_linear_572_3534)" />
      <defs>
        <linearGradient
          id="paint0_linear_572_3534"
          x1="-7.45058e-07"
          y1="81"
          x2="200"
          y2="81"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}
