import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

export const ProductHuntBottomLogo: React.FC<UnknownObject> = () => {
  return (
    <svg
      width="216"
      height="24"
      viewBox="0 0 216 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_539_3105)">
        <path
          d="M14.1182 7.57115C14.1182 5.59606 12.5564 4.39904 10.5351 4.39904H5.11442V10.7133H10.5351C12.5564 10.7133 14.1182 9.5163 14.1182 7.57115ZM0 23.9404V0H11.2701C16.507 0 19.3552 3.44143 19.3552 7.57115C19.3552 11.6709 16.4764 15.1124 11.2701 15.1124H5.14504V23.9404H0Z"
          fill="currentColor"
        />
        <path
          d="M22.4482 23.9397V6.49317H27.042V8.79744C28.2977 7.33109 30.4414 6.07422 32.6158 6.07422V10.4433C32.279 10.3835 31.8808 10.3536 31.3602 10.3536C29.8289 10.3536 27.8077 11.1915 27.0727 12.2987V23.9697H22.4482V23.9397Z"
          fill="currentColor"
        />
        <path
          d="M48.0205 14.992C48.0205 12.3586 46.428 10.0543 43.5493 10.0543C40.7011 10.0543 39.1086 12.3586 39.1086 14.992C39.1086 17.6853 40.7011 19.9596 43.5493 19.9596C46.428 19.9596 48.0205 17.6554 48.0205 14.992ZM34.3311 14.992C34.3311 10.174 37.7917 6.07422 43.5186 6.07422C49.3068 6.07422 52.7675 10.174 52.7675 14.992C52.7675 19.81 49.3068 23.9397 43.5186 23.9397C37.7917 23.9397 34.3311 19.81 34.3311 14.992Z"
          fill="currentColor"
        />
        <path
          d="M68.2023 18.0151V12.0001C67.4367 10.8629 65.783 10.055 64.2823 10.055C61.6485 10.055 59.8417 12.06 59.8417 14.9927C59.8417 17.9553 61.6485 19.9603 64.2823 19.9603C65.783 19.9603 67.4367 19.1523 68.2023 18.0151ZM68.2023 23.9404V21.3369C66.8242 23.0426 64.9561 23.9404 62.8123 23.9404C58.4329 23.9404 55.0947 20.7084 55.0947 14.9927C55.0947 9.45645 58.3716 6.07487 62.8123 6.07487C64.8642 6.07487 66.8242 6.91278 68.2023 8.67839V0H72.8268V23.9404H68.2023Z"
          fill="currentColor"
        />
        <path
          d="M89.1195 23.9407V21.3671C87.9251 22.624 85.8426 23.9407 82.9944 23.9407C79.1663 23.9407 77.3594 21.9058 77.3594 18.584V6.49414H81.9532V16.8184C81.9532 19.1825 83.2088 19.9606 85.1688 19.9606C86.9451 19.9606 88.3538 19.003 89.1501 18.0154V6.49414H93.7439V23.9407H89.1195Z"
          fill="currentColor"
        />
        <path
          d="M97.1436 14.992C97.1436 9.78498 101.033 6.07422 106.392 6.07422C109.976 6.07422 112.119 7.60042 113.283 9.15654L110.282 11.9097C109.455 10.7127 108.199 10.0842 106.607 10.0842C103.82 10.0842 101.89 12.0593 101.89 15.0219C101.89 17.9846 103.85 19.9896 106.607 19.9896C108.199 19.9896 109.455 19.2714 110.282 18.1342L113.283 20.8873C112.119 22.4435 109.976 23.9996 106.392 23.9996C101.033 23.9397 97.1436 20.229 97.1436 14.992Z"
          fill="currentColor"
        />
        <path
          d="M117.234 19.2428V10.3849H114.355V6.46462H117.234V1.82617H121.828V6.49455H125.349V10.4148H121.828V18.0757C121.828 19.1829 122.409 19.9909 123.42 19.9909C124.094 19.9909 124.768 19.7515 125.013 19.4523L125.993 22.8638C125.319 23.4623 124.094 23.971 122.164 23.971C118.918 23.9411 117.234 22.3251 117.234 19.2428Z"
          fill="currentColor"
        />
        <path
          d="M154.688 23.9404V13.6161H143.326V23.9404H138.212V0H143.326V9.24697H154.688V0H159.864V23.9404H154.688Z"
          fill="currentColor"
        />
        <path
          d="M176.248 23.9407V21.3671C175.054 22.624 172.971 23.9407 170.123 23.9407C166.295 23.9407 164.488 21.9058 164.488 18.584V6.49414H169.082V16.8184C169.082 19.1825 170.338 19.9606 172.298 19.9606C174.074 19.9606 175.483 19.003 176.279 18.0154V6.49414H180.873V23.9407H176.248Z"
          fill="currentColor"
        />
        <path
          d="M197.135 23.9397V13.2264C197.135 10.8623 195.879 10.0543 193.919 10.0543C192.112 10.0543 190.734 11.0418 189.938 12.0294V23.9397H185.344V6.49317H189.938V8.67773C191.071 7.42086 193.215 6.07422 196.032 6.07422C199.891 6.07422 201.729 8.19893 201.729 11.4907V23.9397H197.135Z"
          fill="currentColor"
        />
        <path
          d="M207.241 19.2428V10.3849H204.362V6.46462H207.241V1.82617H211.835V6.49455H215.357V10.4148H211.835V18.0757C211.835 19.1829 212.417 19.9909 213.427 19.9909C214.101 19.9909 214.775 19.7515 215.02 19.4523L216 22.8638C215.326 23.4623 214.101 23.971 212.172 23.971C208.925 23.9411 207.241 22.3251 207.241 19.2428Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_539_3105">
          <rect width="216" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
