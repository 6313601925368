import { LinkedInSocialIcon } from 'src/common/components/icons/social/LinkedInSocialIcon'
import { TwitterSocialIcon } from 'src/common/components/icons/social/TwitterSocialIcon'

import { Footer1 } from './_footers/Footer1/Footer1'
import { Footer2 } from './_footers/Footer2/Footer2'
import { Footer3 } from './_footers/Footer3/Footer3'
import Image1 from './_images/image1.png'
import Image2 from './_images/image2.png'
import Image3 from './_images/image3.png'
import { FounderCardProps } from './FounderCard/FounderCard'

export const PAGE_FOUNDERS_BLOCK_CARDS: Array<FounderCardProps> = [
  {
    name: 'Alex Svinov',
    position: 'CEO',
    description:
      'Co-founder/CEO in four succesfull startups with exits. Mentors companies on growth and building high-performance remote teams @ Alchemist. Contributes to business community @ Forbes. Loves creating new things with technology. Father of three in his heart, Buddhist in his mind.',
    socialLinks: [
      {
        icon: <LinkedInSocialIcon />,
        href: 'https://www.linkedin.com/in/alex-svinov/',
      },
    ],
    image: Image1.src,
    footer: <Footer1 />,
  },
  {
    name: 'Rinat Khat',
    position: 'CPO',
    description:
      'Graduate of CS school, Innopolis University. Founded Dizz, an EdTech startup teaching kids programming with Minecraft game techniques. Ex-CPO at Studybay - #1 Tututorship platform for students. He loves playing tennis, chess and Twitter. ',
    socialLinks: [
      {
        icon: <LinkedInSocialIcon />,
        href: 'https://www.linkedin.com/in/decaster/',
      },
      {
        icon: <TwitterSocialIcon />,
        href: 'https://twitter.com/RinatKhat',
      },
    ],
    image: Image2.src,
    footer: <Footer2 />,
  },
  {
    name: 'German Pundorovsky',
    position: 'CMO',
    description:
      "Graduate, School of C by Starta VC. Startup marketing mentor with 100+ webinars completed. He co-founded Coding Invaders - India's #1 EdTech platform teaching software development. Ex-CMO at Qmarketing Academy - sold to Skyeng 2021.",
    socialLinks: [
      {
        icon: <LinkedInSocialIcon />,
        href: 'https://www.linkedin.com/in/german-p/',
      },
    ],
    image: Image3.src,
    footer: <Footer3 />,
  },
]
