import React from 'react'

import {
  AwardBlockCard,
  AwardBlockCardProps,
} from './AwardBlockCard/AwardBlockCard'

import s from './AwardBlockInfo.module.scss'

export type AwardBlockInfoProps = {
  cards: Array<AwardBlockCardProps>
}

export const AwardBlockInfo: React.FC<AwardBlockInfoProps> = ({ cards }) => {
  return (
    <div className={s.AwardBlockInfo}>
      {cards.map((card, i) => (
        <AwardBlockCard key={i} {...card} />
      ))}
    </div>
  )
}
