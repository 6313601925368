import React from 'react'

import clsx from 'clsx'

import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'

import { AccordionImages } from '../_common/AccordionImages/AccordionImages'
import { AccordionList } from '../_common/AccordionList/AccordionList'
import { AccordionContentSlide } from '../VettingAccordion'

import s from './AccordionMobile.module.scss'

export type AccordionMobileProps = {
  slides: Array<AccordionContentSlide>
  activeSlideOrder: number
  isVisible: boolean
  className: string

  setActiveSlideOrder: React.Dispatch<React.SetStateAction<number>>
}

export const AccordionMobile: React.FC<AccordionMobileProps> = ({
  slides,
  activeSlideOrder,
  isVisible,
  className,
  setActiveSlideOrder,
}) => {
  return (
    <div className={clsx(s.AccordionMobile, className)}>
      <Typography
        className={s.AccordionMobile__title}
        variant={EnumTypographyVariants.H3}
        text="Our vetting process lets in only top 2% developers"
      />

      <AccordionImages
        currentSlide={slides[activeSlideOrder]}
        className={s.AccordionMobile__images}
      />

      <AccordionList
        slides={slides}
        isVisible={isVisible}
        activeSlideOrder={activeSlideOrder}
        setActiveSlideOrder={setActiveSlideOrder}
      />
    </div>
  )
}
