import React from 'react'

import { Icon } from 'src/common/components/Icon/Icon'
import { ArrowUpIcon } from 'src/common/components/icons/ArrowUpIcon'

import s from './AwardBlockCard.module.scss'

export type AwardBlockCardProps = {
  topLogo: React.ReactNode
  bottomLogo: React.ReactNode
  href: string
}

export const AwardBlockCard: React.FC<AwardBlockCardProps> = ({
  bottomLogo,
  topLogo,
  href,
}) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="nofollow noreferrer"
      className={s.AwardBlockCard}
    >
      <div className={s.AwardBlockCard__topLogo}>
        {topLogo}
        <Icon icon={<ArrowUpIcon />} className={s.AwardBlockCard__icon} />
      </div>

      <div className={s.AwardBlockCard__bottomLogo}>{bottomLogo}</div>
    </a>
  )
}
