import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

export const AwardLeftIcon: React.FC<UnknownObject> = () => {
  return (
    <svg
      width="425"
      height="162"
      viewBox="0 0 425 162"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M425 117H6.00001" stroke="#9FA1FF" />
      <circle r="2.5" transform="matrix(-1 0 0 1 3 117)" stroke="#9FA1FF" />
      <circle r="2.5" transform="matrix(-1 0 0 1 57 159)" stroke="#9FA1FF" />
      <circle r="2.5" transform="matrix(-1 0 0 1 95 3)" stroke="#9FA1FF" />
      <path d="M425 103.5H195.5L96.5001 4.5" stroke="#9FA1FF" />
      <path d="M425 131H129L59.5002 158" stroke="#9FA1FF" />
      <rect
        width="200"
        height="162"
        transform="matrix(-1 0 0 1 425 0)"
        fill="url(#paint0_linear_572_3536)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_572_3536"
          x1="-7.45058e-07"
          y1="81"
          x2="200"
          y2="81"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}
