import React from 'react'

import clsx from 'clsx'

import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'

import s from './AccordionItemDescription.module.scss'

export type AccordionItemDescriptionProps = {
  description: string | string[]
  isActive: boolean
}

export const AccordionItemDescription: React.FC<
  AccordionItemDescriptionProps
> = ({ description, isActive }) => {
  if (!isActive) {
    return null
  }

  return (
    <>
      {Array.isArray(description) ? (
        <ul className={s.AccordionItemDescription__list}>
          {description.map((text, i) => (
            <Typography
              key={i}
              text={text}
              variant={EnumTypographyVariants.H8}
              className={clsx(
                s.AccordionItemDescription__listItem,
                s.AccordionItemDescription__description
              )}
              as="li"
            />
          ))}
        </ul>
      ) : (
        <Typography
          variant={EnumTypographyVariants.H8}
          className={s.AccordionItemDescription__description}
        >
          {description}
        </Typography>
      )}
    </>
  )
}
