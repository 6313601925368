import React from 'react'

import clsx from 'clsx'

import { Button } from '@insquad/tools'

import { ArrowIcon } from 'src/common/components/icons/ArrowIcon'
import { useAuthRedirect } from 'src/common/hooks/useAuthRedirect'
import { AnalyticEventEnum } from 'src/utils/analytic/analyticEventEnum'
import { useAnalytic } from 'src/utils/analytic/useAnalytic'

import {
  StartupBlockInfo,
  StartupBlockInfoItem,
} from './StartupBlockInfo/StartupBlockInfo'

import s from './PageStartupBlock.module.scss'

export type PageStartupBlockType = {
  className?: string
  title: string
  items: Array<StartupBlockInfoItem>
  pageAnalytic: string
}

export const PageStartupBlock: React.FC<PageStartupBlockType> = ({
  className,
  title,
  items,
  pageAnalytic,
}) => {
  const { analytic } = useAnalytic()

  const { handleSignUpRedirect } = useAuthRedirect()

  const handleSignUp = () => {
    analytic.event(AnalyticEventEnum.SIGN_UP, {
      page: pageAnalytic,
      from: 'start hiring',
    })

    handleSignUpRedirect()
  }

  return (
    <div className={clsx('container', className)}>
      <StartupBlockInfo title={title} items={items} />

      <Button
        id="button-block-starthiring"
        onClick={handleSignUp}
        classes={{
          root: s.PageStartupBlock__button,
          iconRight: s.PageStartupBlock__icon,
        }}
        iconRight={<ArrowIcon />}
      >
        Start hiring developers
      </Button>
    </div>
  )
}
