import React from 'react'

import Image from 'next/image'

import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'

import s from './FounderCard.module.scss'

export type FounderCardProps = {
  name: string
  position: string
  description: string
  socialLinks: Array<{ icon: React.ReactNode; href: string }>
  image: string
  footer: React.ReactNode
}

export const FounderCard: React.FC<FounderCardProps> = ({
  description,
  name,
  position,
  socialLinks,
  image,
  footer,
}) => {
  return (
    <div className={s.FounderCard}>
      <div className={s.FounderCard__image}>
        <Image
          src={image}
          alt={name}
          width={352}
          height={206}
          quality={100}
          layout="responsive"
          unoptimized
        />
      </div>

      <div className={s.FounderCard__titleWrapper}>
        <Typography
          className={s.FounderCard__title}
          variant={EnumTypographyVariants.H4}
        >
          {name}
        </Typography>

        {!!socialLinks.length && (
          <div className={s.FounderCard__socialLinks}>
            {socialLinks.map(({ icon, href }, i) => (
              <a
                key={i}
                href={href}
                target="_blank"
                rel="nofollow noreferrer"
                className={s.FounderCard__socialLink}
              >
                {icon}
              </a>
            ))}
          </div>
        )}
      </div>

      <Typography
        variant={EnumTypographyVariants.H7}
        className={s.FounderCard__position}
        text={position}
      />

      <Typography
        variant={EnumTypographyVariants.H8}
        className={s.FounderCard__description}
        text={description}
      />

      {footer}
    </div>
  )
}
