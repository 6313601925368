import React, { useState } from 'react'

import SwiperCore from 'swiper'

import { AnchorIdsEnum } from 'src/common/enums/anchorIdsEnum'
import { UnknownObject } from 'src/utils/reactTypes'

import { MediaBlockSlider } from './MediaBlockSlider/MediaBlockSlider'
import { MediaBlockTitle } from './MediaBlockTitle/MediaBlockTitle'
import { PAGE_MEDIA_BLOCK_SLIDES } from './pageMediaBlockSlides'

export const PageMediaBlock: React.FC<UnknownObject> = () => {
  const [swiperRef, setSwiperRef] = useState<SwiperCore | null>(null)

  const handlePrev = () => {
    swiperRef?.slidePrev()
  }

  const handleNext = () => {
    swiperRef?.slideNext()
  }

  return (
    <div id={AnchorIdsEnum.MEDIA_BLOCK_ANCHOR}>
      <MediaBlockTitle onPrev={handlePrev} onNext={handleNext} />
      <MediaBlockSlider
        slides={PAGE_MEDIA_BLOCK_SLIDES}
        onSwiper={setSwiperRef}
      />
    </div>
  )
}
