import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

import TopImage from './_images/topImage.png'

import s from './ImageBlock4.module.scss'

export const ImageBlock4: React.FC<UnknownObject> = () => {
  return (
    <div className={s.ImageBlock4}>
      <div className={s.ImageBlock4__topImageWrapper}>
        <div className={s.ImageBlock4__topImage}>
          <img src={TopImage.src} alt="" />
        </div>
      </div>
    </div>
  )
}
