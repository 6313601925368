import React from 'react'

import { AccordionContentSlide } from '../../VettingAccordion'
import { AccordionItem } from '../AccordionItem/AccordionItem'

import s from './AccordionList.module.scss'

export type AccordionListProps = {
  slides: Array<AccordionContentSlide>
  activeSlideOrder: number
  isVisible: boolean

  setActiveSlideOrder: React.Dispatch<React.SetStateAction<number>>
}

export const AccordionList: React.FC<AccordionListProps> = ({
  slides,
  isVisible,
  activeSlideOrder,
  setActiveSlideOrder,
}) => {
  const handleChangeActiveItem = () => {
    setActiveSlideOrder((prev) => {
      if (prev === slides[slides.length - 1].order) {
        return slides[0].order
      }

      return prev + 1
    })
  }

  const handleItemClick = (slideOrder: number) => {
    return () => {
      setActiveSlideOrder(slideOrder)
    }
  }

  return (
    <ul className={s.AccordionList__list}>
      {slides.map((slide, i) => (
        <li key={i} className={s.AccordionList__listItem}>
          <AccordionItem
            title={slide.title}
            description={slide.description}
            order={slide.order}
            isActive={isVisible && slide.order === activeSlideOrder}
            onChangeActiveItem={handleChangeActiveItem}
            onClick={handleItemClick(slide.order)}
          />
        </li>
      ))}
    </ul>
  )
}
