import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

import BottomImage from './_images/bottomImage.png'
import TopImage from './_images/topImage.png'

import s from './ImageBlock2.module.scss'

export const ImageBlock2: React.FC<UnknownObject> = () => {
  return (
    <div className={s.ImageBlock2}>
      <div className={s.ImageBlock2__topImageWrapper}>
        <div className={s.ImageBlock2__topImage}>
          <img src={TopImage.src} alt="" />
        </div>
      </div>

      <div className={s.ImageBlock2__bottomImageWrapper}>
        <div className={s.ImageBlock2__bottomImage}>
          <img src={BottomImage.src} alt="" />
        </div>
      </div>
    </div>
  )
}
