import React from 'react'

import clsx from 'clsx'

import { Icon } from 'src/common/components/Icon/Icon'
import { ArrowIcon } from 'src/common/components/icons/ArrowIcon'
import {
  EnumTypographyVariants,
  Typography,
} from 'src/common/components/Typography/Typography'

import s from './MediaBlockTitle.module.scss'

export type MediaBlockTitleProps = {
  onNext: () => void
  onPrev: () => void
}

export const MediaBlockTitle: React.FC<MediaBlockTitleProps> = ({
  onPrev,
  onNext,
}) => {
  return (
    <div className={clsx(s.MediaBlockTitle, 'container')}>
      <Typography
        variant={EnumTypographyVariants.H2}
        text="Insquad in media"
        className={s.MediaBlockTitle__title}
      />

      <div className={s.MediaBlockTitle__arrows}>
        <Icon
          icon={<ArrowIcon />}
          onClick={onPrev}
          className={s.MediaBlockTitle__arrow_left}
        />
        <Icon
          icon={<ArrowIcon />}
          onClick={onNext}
          className={s.MediaBlockTitle__arrow_right}
        />
      </div>
    </div>
  )
}
