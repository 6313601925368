import React from 'react'

import { UnknownObject } from 'src/utils/reactTypes'

export const MoneyBagIcon: React.FC<UnknownObject> = () => {
  return (
    <svg
      width="32"
      height="36"
      viewBox="0 0 32 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5733 18.6392C20.309 17.8917 19.8194 17.2446 19.1721 16.7869C18.5247 16.3291 17.7513 16.0833 16.9585 16.0833V15.125H15.0418V16.0833C14.0252 16.0833 13.0501 16.4872 12.3313 17.2061C11.6124 17.925 11.2085 18.9 11.2085 19.9167C11.2085 20.9333 11.6124 21.9084 12.3313 22.6272C13.0501 23.3461 14.0252 23.75 15.0418 23.75V27.5833C14.2081 27.5833 13.498 27.0515 13.2335 26.3059C13.1942 26.1839 13.1309 26.071 13.0473 25.9739C12.9637 25.8768 12.8614 25.7975 12.7466 25.7406C12.6318 25.6838 12.5067 25.6505 12.3788 25.6428C12.2509 25.6351 12.1227 25.6531 12.0019 25.6958C11.8811 25.7385 11.7701 25.8049 11.6754 25.8913C11.5807 25.9776 11.5043 26.0821 11.4507 26.1985C11.3971 26.3149 11.3674 26.4408 11.3633 26.5689C11.3592 26.697 11.3809 26.8246 11.427 26.9441C11.6913 27.6916 12.1809 28.3388 12.8283 28.7965C13.4756 29.2542 14.249 29.5 15.0418 29.5V30.4583H16.9585V29.5C17.9752 29.5 18.9502 29.0961 19.6691 28.3772C20.388 27.6584 20.7918 26.6833 20.7918 25.6667C20.7918 24.65 20.388 23.675 19.6691 22.9561C18.9502 22.2372 17.9752 21.8333 16.9585 21.8333V18C17.3549 17.9999 17.7415 18.1227 18.0652 18.3515C18.3889 18.5803 18.6337 18.9038 18.7659 19.2775C18.8505 19.5171 19.0269 19.7134 19.2563 19.823C19.3698 19.8773 19.4929 19.9087 19.6186 19.9154C19.7443 19.9221 19.8701 19.904 19.9887 19.862C20.1074 19.8201 20.2167 19.7553 20.3103 19.6711C20.4039 19.587 20.48 19.4852 20.5343 19.3717C20.5886 19.2581 20.62 19.135 20.6267 19.0093C20.6334 18.8837 20.6152 18.7579 20.5733 18.6392ZM15.0418 18C14.5335 18 14.046 18.2019 13.6865 18.5614C13.3271 18.9208 13.1252 19.4083 13.1252 19.9167C13.1252 20.425 13.3271 20.9125 13.6865 21.272C14.046 21.6314 14.5335 21.8333 15.0418 21.8333V18ZM16.9585 27.5833C17.4668 27.5833 17.9543 27.3814 18.3138 27.022C18.6732 26.6625 18.8752 26.175 18.8752 25.6667C18.8752 25.1583 18.6732 24.6708 18.3138 24.3114C17.9543 23.9519 17.4668 23.75 16.9585 23.75V27.5833Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.6051 3.26179C7.95685 2.11083 11.8151 0.75 16.0375 0.75C20.1718 0.75 23.9284 2.05429 26.2677 3.18992L26.4 3.25412C27.1053 3.60296 27.6746 3.93263 28.0751 4.2L24.5351 9.375C32.6962 17.7182 39.0001 35.2471 16.0375 35.2471C-6.92511 35.2471 -0.790814 18.0364 7.44127 9.375L3.92419 4.2C4.19539 4.02271 4.53944 3.81667 4.9496 3.59817C5.15277 3.48892 5.37127 3.37679 5.6051 3.26275V3.26179ZM22.26 9.306L25.0938 5.16312C22.4584 5.35287 19.3227 5.97196 16.3039 6.84596C14.1477 7.46887 11.7519 7.374 9.53331 6.96479C8.97425 6.86115 8.41903 6.73776 7.86869 6.59487L9.70868 9.30408C13.6522 10.708 18.3155 10.708 22.26 9.306ZM8.60181 10.9371C13.2162 12.7196 18.7621 12.7196 23.3764 10.9352C25.3026 12.9656 26.9138 15.273 28.1566 17.7805C29.4523 20.4236 30.1576 23.0657 30.0426 25.3427C29.9314 27.5402 29.067 29.4176 27.0929 30.8129C25.0353 32.2667 21.5748 33.3305 16.0366 33.3305C10.4926 33.3305 7.01577 32.2849 4.93906 30.8474C2.95052 29.4693 2.07748 27.6178 1.95385 25.4501C1.82448 23.198 2.51448 20.5693 3.80535 17.908C5.03681 15.3703 6.7551 12.9275 8.60181 10.9371ZM7.49973 4.50283C8.26639 4.73092 9.06756 4.92929 9.88023 5.07975C11.9406 5.45925 14.0068 5.51483 15.7701 5.00404C17.825 4.40541 19.915 3.93461 22.0281 3.59433C20.2647 3.06342 18.2024 2.66667 16.0366 2.66667C12.7351 2.66667 9.65598 3.58763 7.49973 4.50283Z"
        fill="currentColor"
      />
    </svg>
  )
}
